.hp-footer {
  text-align: center;
  padding: 20px;
  border-top: 1px solid $off-white;
  margin-left: -20px;
  margin-right: -20px;

  @media (min-width: 500px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.hp-footer__text {
  margin: 0;
  line-height: 1;
}
