@import "./variables.scss";
@import "./dank-mono.css";
@import "./styles/components";
@import "../components/Editor/styles.scss";
@import "../components/Typography/styles.scss";
@import "../components/IconBtn/styles.scss";
@import "../components/Logo/styles.scss";
@import "../components/Button/styles.scss";
@import "../components/Instructions/styles.scss";
@import "../components/BetaCTA/styles.scss";
@import "../components/Featured/styles.scss";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
textarea,
button,
input {
  font-family: Lato, sans-serif;
}

body {
  color: $hacker-blue;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.hp-body {
  margin: 0;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
}

.hp-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .hp-header__btn {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  @media (max-width: 600px) {
    .hp-header__btn {
      display: none;
    }
  }
}

.hp-content {
  flex-direction: column;
  flex: 1;
  display: flex;
  max-width: 1000px;
  margin: auto;
}

.hp-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .hp-body {
    padding-left: 20px;
    margin: 0;
  }
}

.hp-nav {
  width: 80px;
  display: flex;
  flex-direction: column;
  background: #11182c;
  position: fixed;
  height: 100vh;
  padding-top: 5px;
}

.hp-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hp-nav__link {
  width: 45px;
  height: 45px;
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
  border-radius: 50%;
  margin-top: 25px;
}
